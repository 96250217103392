.app__bout-content {
     width: 300px;
     min-height: 250px;
     position: relative;
     border: 2px solid orange;
     padding: 15px;
     color: rgba(0, 0, 0, 0.5);

     font-size: 20px;
     display: inline-block;
     
     .app__about-title {
        font-size: 1.5rem;
        color: orange;
        text-transform: uppercase;
        letter-spacing: 3px;
        position: absolute;
        bottom: 0;
        left: 0;
        margin-left: -40px;
        transform: rotate(270deg);
        transform-origin: 0 0;
     }
}



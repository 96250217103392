
  .header__cont {
      display: flex;
      
      flex-direction: column;

      
  }
  .home-title{
    display: flex;
    font-size: 2.75rem;
    font-weight: 800;
    padding-right: 180px;
    color: #774360;
    @media screen and (max-width: 550px) {
      padding-right: 0px;
    }
    
  }

 
  .app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    padding: 6rem 2rem 0rem;
  
    @media screen and (min-width: 2000px) {
      padding-top: 8rem;
    }
  
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  
    @media screen and (max-width: 450px) {
      padding: 6rem 1rem 2rem;
    }
  }


  .app__header-social {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 180px;
    flex-direction: row;
    gap: 1rem;
  
    padding: 15px 0px;
  
    div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--white-color);
      margin: 0.25rem 0;
      border: 1px solid var(--lightGray-color);
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      transition: all 0.3s ease-in-out;
  

    }
  }

  .Rchevron {
    margin: 3px 5px;
  }

  


.footer__popup {
    
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;

}

.footer__popup-inner {
    position: relative;
    padding: 32px;
    width: 100vh;
    max-width: 640px;
    background-color: #FFF;
    border-radius: 15px;
    
    .close-btn {
        padding: 0.2rem 0.6rem;
        padding-bottom: 0.1rem;
        border-radius: 10px;
        border: none;
        background-color: var(--secondary-color);
        
        
        color: var(--white-color);
        font-family: var(--font-base);
        
        cursor: pointer;

        &:hover {
        background-color: #2430af;
        }
        position: absolute;
        top: 16px;
        right: 16px;
    }
    @media screen and (max-width: 900px) {
        width: 70vh;
        .close-btn {   
            padding: 0.35rem 0.7rem;
            padding-bottom: 0.1rem;
            
        }
      }
    @media screen and (max-width: 420px) { // tel

        width: 55vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        
        .close-btn {   
            padding: 0.3rem 0.4rem;
            padding-bottom: 0.1rem;
            
        }
        .pop-text {
           
            margin-top: 1rem;
        }
      }
}
